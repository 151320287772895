import React from 'react'
import {Container,Carousel} from 'react-bootstrap';
import '../styles/slider.css'

function Slider(){
    return(

        <div className='slide-main  overflow-hidden' >
            <Container fluid className='sliderContainer'>
  
            {/***************************************** third row *****************************/}
                <Carousel className='slide-carousel'>
                    
                <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider10.jpeg"
                        alt="slider10"
                        />
                    </Carousel.Item>
                <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slidewr.jpeg"
                        alt="slidewr10"
                        />
                    </Carousel.Item>   
                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider1111.jpeg"
                        alt="slider1111"
                        />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/neet_toppers.jpeg"
                        alt="slider12"
                        />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider11.jpg"
                        alt="slider11"
                        />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider2.jpg"
                        alt="slider1"
                        />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img
                            className="w-100"
                            src="/slider/slider1.jpeg"
                            alt="slider2" 
                        />
                    </Carousel.Item>
        
                    <Carousel.Item >
                        <img
                            className="w-100"
                            src="/slider/slider3.jpeg"
                            alt="slider3"
                        />
                    </Carousel.Item>
        
                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider33.jpg"
                        alt="slider33"
                        />
                    </Carousel.Item>
        
                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider4.jpeg"
                        alt="slider4"
                        />
                    </Carousel.Item>

                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider5.jpeg"
                        alt="slider5"
                        />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider6.jpeg"
                        alt="slider6"
                        />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider7.jpeg"
                        alt="slider7"
                        />
                    </Carousel.Item>
                    {/* <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider8.jpg"
                        alt="slider8"
                        />
                    </Carousel.Item> */}
                    <Carousel.Item >
                        <img
                        className="w-100"
                        src="/slider/slider9.jpg"
                        alt="slider9"
                        />
                    </Carousel.Item>
                        
                         
                </Carousel>
  
  
            </Container>
  
      </div>

    )
}
export default Slider