import React from "react"
import Layout from "../components/Layout"
import Slider from "../components/Slider"
import { Container, Modal } from "react-bootstrap"
import { Link } from 'gatsby'
import { useState, useEffect } from 'react'
import AOS from 'aos';
import newGip from "../images/new.gif"
import { BookFill, Stars, Calendar3, TrophyFill } from 'react-bootstrap-icons'
import logo from '../images/college Logo.jpeg'
import Head from '../components/Head';


function Home(){

  const [show, setShow] = useState(false);      //notification modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currDate=new Date();
  const currentYear= currDate.getFullYear()
  const nextYear=currentYear+1;
  


  useEffect(() => {
    handleShow();

    AOS.init({
      duration : 1500
    });
    AOS.refresh();

  }, []);

  return(
    <>
      <Head title="Home"/>
      <Layout>
        <Slider/>

        {/***************************************** marquee starts *****************************/}
        <div className='marqueeIndexPage'>
            <Link className="marqueeLink" to="https://docs.google.com/forms/d/e/1FAIpQLScpgUtBmRTvac3jF9ENNMGMYRwXe-1lrqFjrc-U05QYdcZN3Q/viewform" target="_blank" rel="noopener noreferrer">
              <p className="fs-1"><img src={newGip} alt="new gip" />Admissions Open for Science & Commerce Courses &nbsp; {currentYear}-{currentYear
              +1}</p>
            </Link>

        </div>

        {/***************************************** marquee ends *****************************/}

        {/* *********************************   notification start  *************************** */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="notification">
              <figure>
                <img fluid src={logo} alt="ksss college logo" width={100} />
              </figure>

              <h1 className="mb-5">KSSS Pre-University <br /> Sceince and Commerce College</h1>
              <h4 className="my-3">REGISTRATION FOR YEAR-{currentYear}-{currentYear+1}</h4>
              <h1><Link className="click" to="https://docs.google.com/forms/d/e/1FAIpQLScpgUtBmRTvac3jF9ENNMGMYRwXe-1lrqFjrc-U05QYdcZN3Q/viewform" target="_blank" rel="noopener noreferrer">Click Here</Link></h1>
            </div>

          </Modal.Body>
          
        </Modal>
        {/* *********************************   notification ends *************************** */}


        <Container className="overflow-hidden">
          <section className="welcomeSection " >
            <header className="sectionHeader halfBorder mb-5" as="h1" >WELCOME TO K.S.S.S P.U COLLEGE</header>

            <div className="row" >

              <div className="col-lg-6" data-aos="fade-down-right">
                <p className="sectionContent  " >K.S.S.S.P.U. College draws its inspiration from the person and life
                  and teaching of Sri Sri Vishwesh Teerth Swamiji,Universal Master and
                  Model of education. It is situated about 5kms from Hubli on the
                  Pune-Bangalore Highway(NH-4) at Budarshinghi. It is one of the
                  finest centers of learning.The college is recognized by
                  Pre-University Education Board Karnataka.The college offers the
                  students all the advantages of a modern education system without
                  distraction of city life.
                  <br />
                  <br />

                  The campus is located in the pleasant and
                  cool climatic conditions in the outskirts of the town in harmony
                  with serene surrounding of green.This college is a boon to all those
                  who are interested to give value based education to their
                  children.The learner centered education with the concept of all
                  round development of the individual student is the foremost
                  philosophy of this college.The campus is optly named as "Govardhan
                  Campus"
                </p>
              </div>
              <div className=" col-lg-5 welcomeImageContainer" data-aos="fade-down-left" >
                <div className="welcomeImage"><h1 title="ksss college freshers day" className="d-none">ksss college freshers day</h1></div>
              </div>

            </div>
            <div id="infinity"></div>
            
          </section>
        

          <section className="drivingForceSection"  data-aos="zoom-in">
            <header className="sectionHeader halfBorder mb-5" as="h1">THE DRIVING FORCE</header>

            <div>
              <div className="drivingForceImage"><h1 title="driving force and Shri Shri Pejawar Swamiji" className="d-none">driving force and Shri Shri Pejawar Swamiji </h1></div>

              <div className="py-4">
                <h1 className="drivingHeading" >Shri Pejawar Swamiji</h1>
                <p className="sectionContent float-left ">
                  We believe that every child is uniquely created with divine energy
                  which requires a suitable medium of expression. It is our duty to
                  channelize this hidden energy to the world at large through right
                  education, right values and right action. KSSS PU College imbibes
                  these ideals for purposeful education across caste, creed and
                  nationality in the true spirit of Sri Swamiji's vision.
                  <br />
                  <br />
                  Shri Pejawar Swamiji is one of the living saints who are accepted
                  as moral and spiritual leader by all section of the society in our
                  country. A lucid orator, a keen debater, a rapt teacher, a
                  profound scholar, an enquiring critic, a farsighted educational
                  organizer, a compassionate spiritual preceptor.
                </p>
              </div>
            </div>
          </section>
        </Container>

          <Container fluid>
            <section className="quateSection d-flex justify-content-center align-items-center" >
              <div class="quateContent" data-aos="flip-up">
                <h1 className="quate1 " >ಉತ್ತಮ ಶಿಕ್ಷಣವು ಉತ್ತಮ ಭವಿಷ್ಯಕ್ಕೆ ಅಡಿಪಾಯ</h1>
                <h1 className="quate2" ><span>A</span> <span>G</span>ood <span>E</span>ducation is a <span>F</span>oundation for a <span>B</span>etter <span>F</span>uture</h1>
              </div>
            </section>
          </Container>

          <Container >
            <section className="newsProgramSection" >
              <div className="row">
                <div className="latestNews col-md-6 col-12">
                  <div className="me-md-5">
                    <header className="sectionHeader halfBorder mb-5">Latest News</header>
                    <div className="latestNewsCard px-4" data-aos="fade-right">
                      <div className="newsArticle">
                        <figure className="fs-4"><img src={newGip} alt="new gip" /> 03-05-{currentYear} </figure>
                        <article className="fs-2">Admission Open for Science & commerce Courses {currentYear}-{currentYear+1} </article>
                      </div>

                      <div className="newsArticle">
                        <figure className="fs-4"><img src={newGip} alt="new gip" />12-10-2022</figure>
                        <article className="fs-2">All Exams will conduct on October 2022 </article>
                      </div>
                    </div>
                  </div>
                </div>
                
                
                <div className="programs col-md-6 col-12" >
                  <div className="">
                    <header className="sectionHeader halfBorder mb-5" as="h1">Programs</header>
                    <div className="programsCard">
                      <div className="row my-5 ">
                        <div  className="col-12 col-md-6 d-flex align-items-center mb-md-0 mb-5 ms-5 ms-lg-0 ps-lg-5" data-aos="fade-left" data-aos-duration='1000'>
                          <div className="programRound  ">
                            <span><BookFill size={30} /></span>
                          </div>
                          <span className="programRoundLable fs-1"><Link  to="/courses">Academics</Link></span>
                        </div>
                        

                        <div  className="col-12 col-md-6  d-flex align-items-center ms-5 ms-lg-0 ps-lg-5" data-aos="fade-left" data-aos-duration='1300'>
                          <div className="programRound  ">
                            <span><Stars size={30} /></span>
                          </div>
                          <span className="programRoundLable fs-1"><Link  to="/about">About</Link></span>

                        </div>
                      </div>

                      <div className="row my-5">
                        <div className="col-12 col-md-6  d-flex align-items-center mb-md-0 mb-5 ms-5 ms-lg-0 ps-lg-5" data-aos="fade-left" data-aos-duration='1500'>
                          <div className="programRoundLable programRound  ">
                            <span><Calendar3 size={30} /></span>
                          </div>
                          <span className="programRoundLable fs-1"><Link  to="/events">Events</Link></span>
                        </div>

                        <div className="col-12 col-md-6  d-flex align-items-center ms-5 ms-lg-0 ps-lg-5" data-aos="fade-left" data-aos-duration='1800'>
                          <div className="programRound  ">
                            <span><TrophyFill size={30} /></span>
                          </div>
                          <span className="programRoundLable fs-1"><Link  to="/events">Sports</Link></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Container>

          
        
      </Layout>
    </>
  )
}
export default Home   